// Import custom icons
import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core';

interface CustomIcon extends IconDefinition {
    prefix: IconPrefix,
    iconName: IconName,
}

export const faAddPeople: CustomIcon = {
    prefix: 'fas',
    iconName: 'add-people' as IconName,
    icon: [
        18, 14,
        [],
        '',
        'M 6.4160156,0 C 4.5503075,0 3.0273437,1.5229637 3.0273438,3.3886719 c 0,1.8657081 1.5229637,3.3886718 3.3886718,3.3886718 1.8657082,0 3.3886719,-1.5229637 3.3886719,-3.3886718 C 9.8046875,1.5229637 8.2817238,0 6.4160156,0 Z m 0,1 c 1.3252687,0 2.3886719,1.0634032 2.3886719,2.3886719 0,1.3252686 -1.0634032,2.3886718 -2.3886719,2.3886718 -1.3252686,0 -2.3886718,-1.0634032 -2.3886719,-2.3886718 C 4.0273437,2.0634032 5.090747,1 6.4160156,1 Z m 7.8066404,3.1113281 v 1.6660156 h -1.666015 v 1 h 1.666015 v 1.6679688 h 1 V 6.7773437 h 1.666016 v -1 H 15.222656 V 4.1113281 Z M 3.8886719,8.6660156 c -0.898598,0 -1.7610851,0.3568351 -2.3964844,0.9921875 C 0.85678396,10.29363 0.5,11.156077 0.5,12.054688 V 13.5 h 1 V 12.054688 C 1.5,11.420899 1.7510634,10.813406 2.1992188,10.365234 2.6473584,9.9171278 3.2549113,9.6660156 3.8886719,9.6660156 h 5.0566406 c 0.6337723,0 1.2393175,0.2510943 1.6875005,0.6992184 0.448158,0.448159 0.701171,1.055639 0.701171,1.689454 V 13.5 h 1 v -1.445312 c 0,-0.898585 -0.358699,-1.761044 -0.99414,-2.3964849 C 10.704427,9.0228687 9.8439187,8.6660156 8.9453125,8.6660156 Z',
    ],
};

export const faBoltSolid: CustomIcon = {
    prefix: 'fas',
    iconName: 'bolt-solid' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.1113 2-6.1113 7.334h5.5l-0.61133 4.8887 6.1113-7.334h-5.5l0.61133-4.8887z',
    ],
};

export const faBolt: CustomIcon = {
    prefix: 'fas',
    iconName: 'bolt' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.1016 1.5a0.50005 0.50005 0 0 0-0.375 0.17969l-6.1113 7.334a0.50005 0.50005 0 0 0 0.38477 0.82031h4.9336l-0.54102 4.3262a0.50005 0.50005 0 0 0 0.88086 0.38281l6.1113-7.334a0.50005 0.50005 0 0 0-0.38477-0.82031h-4.9336l0.54102-4.3262a0.50005 0.50005 0 0 0-0.50586-0.5625zm-0.69727 2.1309-0.40039 3.1953a0.50005 0.50005 0 0 0 0.49609 0.5625h4.4316l-4.3359 5.2031 0.40039-3.1973a0.50005 0.50005 0 0 0-0.49609-0.56055h-4.4316l4.3359-5.2031z',
    ],
};

export const faChevronDown: CustomIcon = {
    prefix: 'fas',
    iconName: 'chevron-down' as IconName,
    icon: [
        12, 12,
        [],
        '',
        'M1.64648 4.17683L2.35359 3.46973L6.00004 7.11617L9.64648 3.46973L10.3536 4.17683L6.00004 8.53039L1.64648 4.17683Z',
    ],
};

export const faChevronLeft: CustomIcon = {
    prefix: 'fas',
    iconName: 'chevron-left' as IconName,
    icon: [
        12, 12,
        [],
        '',
        'M7.82328 1.64648L8.53039 2.35359L4.88394 6.00004L8.53039 9.64648L7.82328 10.3536L3.46973 6.00004L7.82328 1.64648Z',
    ],
};

export const faChevronRight: CustomIcon = {
    prefix: 'fas',
    iconName: 'chevron-right' as IconName,
    icon: [
        12, 12,
        [],
        '',
        'M4.17683 10.3536L3.46973 9.64649L7.11617 6.00004L3.46973 2.35359L4.17683 1.64648L8.53039 6.00004L4.17683 10.3536Z',
    ],
};

export const faChevronUp: CustomIcon = {
    prefix: 'fas',
    iconName: 'chevron-up' as IconName,
    icon: [
        12, 12,
        [],
        '',
        'M10.3536 7.82279L9.64649 8.5299L6.00004 4.88345L2.35359 8.5299L1.64648 7.82279L6.00004 3.46924L10.3536 7.82279Z',
    ],
};

export const faCommentFilled: CustomIcon = {
    prefix: 'fas',
    iconName: 'comment-filled' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.334 2c-0.87991-0.0023-1.7478 0.2029-2.5332 0.59961-0.94116 0.47038-1.7337 1.193-2.2871 2.0879-0.5534 0.89487-0.84726 1.9264-0.84766 2.9785-0.0023 0.87991 0.20485 1.7478 0.60156 2.5332l-1.2676 3.8008 3.8008-1.2676c0.78541 0.3967 1.6533 0.60386 2.5332 0.60156 1.0522-4e-4 2.0837-0.29426 2.9785-0.84766 0.8949-0.5534 1.6175-1.3459 2.0879-2.2871 0.3967-0.7854 0.60191-1.6533 0.59961-2.5332v-0.33203c-0.0767-1.3896-0.66238-2.7034-1.6465-3.6875-0.984-0.98407-2.298-1.5698-3.6875-1.6465h-0.33203z',
    ],
};

export const faComment: CustomIcon = {
    prefix: 'fas',
    iconName: 'comment' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.334 1.5c-0.95865-0.0025058-1.9041 0.22209-2.7598 0.6543-1.0231 0.5119-1.8846 1.2985-2.4863 2.2715-0.60221 0.9738-0.92144 2.0953-0.92188 3.2402-0.0022576 0.86369 0.23777 1.7 0.58984 2.4863l-1.2305 3.6895a0.50005 0.50005 0 0 0 0.63281 0.63281l3.6895-1.2305c0.78631 0.35207 1.6226 0.5921 2.4863 0.58984 1.145-4.35e-4 2.2665-0.31964 3.2402-0.92188 0.97304-0.60172 1.7596-1.4632 2.2715-2.4863 0.43219-0.85567 0.6568-1.8011 0.6543-2.7598v-0.33203a0.50005 0.50005 0 0 0 0-0.0019532 0.50005 0.50005 0 0 0 0-0.0019531 0.50005 0.50005 0 0 0 0-0.0019531 0.50005 0.50005 0 0 0 0-0.0058594 0.50005 0.50005 0 0 0 0-0.0019531 0.50005 0.50005 0 0 0 0-0.0019531 0.50005 0.50005 0 0 0 0-0.0019532 0.50005 0.50005 0 0 0 0-0.0058593 0.50005 0.50005 0 0 0 0-0.0019531 0.50005 0.50005 0 0 0 0-0.0019532c-0.083476-1.5123-0.72192-2.9427-1.793-4.0137-1.071-1.071-2.5014-1.7095-4.0137-1.793a0.50005 0.50005 0 0 0-0.027344 0h-0.33203zm-0.0019532 1a0.50005 0.50005 0 0 0 0.0019532 0h0.30469c1.2668 0.069887 2.4643 0.60289 3.3613 1.5 0.89715 0.89712 1.4301 2.0945 1.5 3.3613v0.30469a0.50005 0.50005 0 0 0 0 0.0019532c0.0021 0.80116-0.18567 1.5915-0.54688 2.3066a0.50005 0.50005 0 0 0 0 0.0019531c-0.42891 0.85819-1.0883 1.5794-1.9043 2.084-0.81584 0.50457-1.7555 0.77307-2.7148 0.77344a0.50005 0.50005 0 0 0-0.0019532 0c-0.80117 0.002102-1.5915-0.18567-2.3066-0.54688a0.50005 0.50005 0 0 0-0.38281-0.027343l-2.8516 0.94922 0.94922-2.8516a0.50005 0.50005 0 0 0-0.027344-0.38281c-0.36121-0.71512-0.54897-1.5055-0.54688-2.3066a0.50005 0.50005 0 0 0 0-0.0019532c3.647e-4 -0.95935 0.26885-1.8989 0.77344-2.7148 0.50459-0.81593 1.2258-1.4754 2.084-1.9043a0.50005 0.50005 0 0 0 0.0019531 0c0.71514-0.36121 1.5055-0.54897 2.3066-0.54688z',
    ],
};

export const faCopy: CustomIcon = {
    prefix: 'fas',
    iconName: 'copy' as IconName,
    icon: [
        14, 14,
        [],
        '',
        'M 6.4003906 0.5 C 5.9496688 0.5 5.5179271 0.67935266 5.1992188 0.99804688 C 4.8805043 1.3167613 4.7011719 1.7485016 4.7011719 2.1992188 L 4.7011719 2.8007812 A 0.5 0.5 0 0 0 5.2011719 3.3007812 A 0.5 0.5 0 0 0 5.7011719 2.8007812 L 5.7011719 2.1992188 C 5.7011719 2.0133165 5.7748049 1.8365232 5.90625 1.7050781 C 6.0377012 1.5736328 6.2145131 1.5 6.4003906 1.5 L 11.800781 1.5 C 11.986659 1.5 12.16556 1.573722 12.296875 1.7050781 C 12.428361 1.8365529 12.501953 2.0133794 12.501953 2.1992188 L 12.501953 7.6015625 C 12.501953 7.7874019 12.428353 7.9642434 12.296875 8.0957031 C 12.165561 8.2270643 11.986659 8.3007812 11.800781 8.3007812 L 11.201172 8.3007812 A 0.5 0.5 0 0 0 10.701172 8.8007812 A 0.5 0.5 0 0 0 11.201172 9.3007812 L 11.800781 9.3007812 C 12.251503 9.3007812 12.685221 9.1215328 13.003906 8.8027344 C 13.322629 8.4840546 13.501953 8.0523425 13.501953 7.6015625 L 13.501953 2.1992188 C 13.501953 1.7484388 13.322619 1.3167317 13.003906 0.99804688 C 12.685222 0.67926346 12.251503 0.5 11.800781 0.5 L 6.4003906 0.5 z M 2.1992188 4.6992188 C 1.2661586 4.6992188 0.49804687 5.4653809 0.49804688 6.3984375 L 0.49804688 11.798828 C 0.49804688 12.731861 1.2661284 13.5 2.1992188 13.5 L 7.5996094 13.5 C 8.5326997 13.5 9.2988281 12.73186 9.2988281 11.798828 L 9.2988281 6.3984375 C 9.2988281 5.4653824 8.5326695 4.6992188 7.5996094 4.6992188 L 2.1992188 4.6992188 z M 2.1992188 5.6992188 L 7.5996094 5.6992188 C 7.9922279 5.6992188 8.2988281 6.0058139 8.2988281 6.3984375 L 8.2988281 11.798828 C 8.2988281 12.191395 7.9921977 12.5 7.5996094 12.5 L 2.1992188 12.5 C 1.8066303 12.5 1.4980469 12.191394 1.4980469 11.798828 L 1.4980469 6.3984375 C 1.4980469 6.0058154 1.8066001 5.6992188 2.1992188 5.6992188 z ',
    ],
};

export const faCrossLarge: CustomIcon = {
    prefix: 'fas',
    iconName: 'cross-large' as IconName,
    icon: [
        14, 14,
        [],
        '',
        'm2.0508 1.5508a0.5 0.5 0 0 0-0.35352 0.14648 0.5 0.5 0 0 0 0 0.70703l4.5957 4.5957-4.5957 4.5977a0.5 0.5 0 0 0 0 0.70703 0.5 0.5 0 0 0 0.70703 0l4.5957-4.5957 4.5957 4.5957a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-4.5957-4.5977 4.5957-4.5957a0.5 0.5 0 0 0 0-0.70703 0.5 0.5 0 0 0-0.70703 0l-4.5957 4.5957-4.5957-4.5957a0.5 0.5 0 0 0-0.35352-0.14648z',
    ],
};

export const faCrossSmall: CustomIcon = {
    prefix: 'fas',
    iconName: 'cross-small' as IconName,
    icon: [
        6, 6,
        [],
        '',
        'm0.80078 0.38477a0.5 0.5 0 0 0-0.27539 0.14062 0.5 0.5 0 0 0 0 0.70703l1.7676 1.7676-1.7676 1.7676a0.5 0.5 0 0 0 0 0.70703 0.5 0.5 0 0 0 0.70703 0l1.7676-1.7676 1.7676 1.7676a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-1.7676-1.7676 1.7676-1.7676a0.5 0.5 0 0 0 0-0.70703 0.5 0.5 0 0 0-0.70703 0l-1.7676 1.7676-1.7676-1.7676a0.5 0.5 0 0 0-0.43164-0.14062z',
    ],
};

export const faEnterLarge: CustomIcon = {
    prefix: 'fas',
    iconName: 'enter-large' as IconName,
    icon: [
        24, 24,
        [],
        '',
        'm20 3.5a0.5 0.5 0 0 0-0.5 0.5v7c0 0.9285-0.36885 1.8182-1.0254 2.4746-0.65646 0.65654-1.5461 1.0254-2.4746 1.0254h-10.793l4.1465-4.1465a0.5 0.5 0 0 0 0-0.70703 0.5 0.5 0 0 0-0.70703 0l-5 5a0.5 0.5 0 0 0-0.14648 0.35352 0.5 0.5 0 0 0 0.14648 0.35352l5 5a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-4.1465-4.1465h10.793c1.1933 0 2.3379-0.4745 3.1816-1.3184 0.84385-0.84374 1.3184-1.9883 1.3184-3.1816v-7a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faEnter: CustomIcon = {
    prefix: 'fas',
    iconName: 'enter' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm13.334 2.166a0.5 0.5 0 0 0-0.5 0.5v4.668c0 0.57485-0.22831 1.1248-0.63476 1.5312-0.40646 0.40646-0.95831 0.63477-1.5332 0.63477h-6.793l2.4805-2.4805a0.5 0.5 0 0 0 0-0.70703 0.5 0.5 0 0 0-0.70703 0l-3.334 3.334a0.5 0.5 0 0 0-0.14648 0.35352 0.5 0.5 0 0 0 0.14648 0.35352l3.334 3.334a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-2.4805-2.4805h6.793c0.8397 0 1.6465-0.334 2.2402-0.92773 0.59374-0.59374 0.92773-1.3986 0.92773-2.2383v-4.668a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faEyeClosed: CustomIcon = {
    prefix: 'fas',
    iconName: 'eye-closed' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm0.66602 0.16602a0.5 0.5 0 0 0-0.35352 0.14648 0.5 0.5 0 0 0 0 0.70703l3.0137 3.0137c-1.2516 1.047-2.3264 2.2896-3.0996 3.7305a0.50005 0.50005 0 0 0-0.0078125 0.45898s2.7336 5.6113 7.7812 5.6113a0.50005 0.50005 0 0 0 0.0078125 0c1.3866-0.02265 2.7088-0.49615 3.8574-1.2617l3.1152 3.1152a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-3.3711-3.3711-0.001953-0.003906-2.5605-2.5586-2.8008-2.8008-2.5586-2.5605a0.50005 0.50005 0 0 0-0.0019531 0l-3.373-3.373a0.5 0.5 0 0 0-0.35352-0.14648zm7.334 2c-0.5094-0.001049-1.0177 0.057735-1.5137 0.17383a0.50005 0.50005 0 0 0-0.24023 0.83984l7.293 7.3008a0.50005 0.50005 0 0 0 0.73633-0.03125c0.57511-0.68346 1.0769-1.4251 1.498-2.2129a0.50005 0.50005 0 0 0 0.007812-0.46094s-2.733-5.6078-7.7793-5.6094h-0.0019531zm-0.0019531 1a0.50005 0.50005 0 0 0 0.0019531 0c4.1016 0 6.5163 4.4419 6.7285 4.8398-0.26646 0.47008-0.57827 0.91071-0.90625 1.3398l-6.1406-6.1445c0.10632-0.00585 0.20979-0.035407 0.31641-0.035156zm-3.9688 1.5703 1.9121 1.9102v0.0019531c-0.090637 0.12982-0.21063 0.23942-0.27539 0.38477-0.13663 0.30666-0.20892 0.63699-0.21484 0.97266-0.00591 0.33565 0.055899 0.66917 0.18164 0.98047 0.12574 0.3113 0.31144 0.59464 0.54883 0.83203 0.23739 0.23737 0.52075 0.42309 0.83203 0.54883 0.31131 0.12578 0.64472 0.18764 0.98047 0.18164 0.33574-0.0059 0.66602-0.07822 0.97266-0.21484 0.14534-0.06476 0.25494-0.18476 0.38477-0.27539h0.0019531l1.7813 1.7832c-0.94989 0.58916-2.0137 0.97311-3.1387 0.99219-4.0983-0.003045-6.5114-4.4401-6.7246-4.8398 0.71092-1.2491 1.6491-2.3445 2.7578-3.2578zm2.6152 2.6152 2.0039 2.0039c-0.032262 0.017311-0.054345 0.049506-0.087891 0.064453-0.18402 0.081992-0.38262 0.12538-0.58398 0.12891-0.20133 0.0035988-0.40112-0.031963-0.58789-0.10742-0.18678-0.075444-0.35755-0.18763-0.5-0.33008-0.14245-0.14245-0.25463-0.31322-0.33008-0.5-0.075438-0.18676-0.11097-0.38646-0.10742-0.58789 0.0035551-0.20141 0.046921-0.39998 0.12891-0.58398 0.014946-0.033543 0.047143-0.055631 0.064453-0.087891z',
    ],
};

export const faEye: CustomIcon = {
    prefix: 'fas',
    iconName: 'eye' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8 2.166c-5.0476 0-7.7812 5.6094-7.7812 5.6094a0.50005 0.50005 0 0 0 0 0.44727s2.7336 5.6113 7.7812 5.6113c5.0476 0 7.7812-5.6113 7.7812-5.6113a0.50005 0.50005 0 0 0 0-0.44727s-2.7337-5.6094-7.7812-5.6094zm0 1c4.0967 0 6.5071 4.4266 6.7246 4.834-0.21854 0.40935-2.6289 4.834-6.7246 4.834-4.0958 0-6.5061-4.4246-6.7246-4.834 0.21746-0.40741 2.6278-4.834 6.7246-4.834zm0 2.334c-1.3748 0-2.5 1.1252-2.5 2.5s1.1252 2.5 2.5 2.5 2.5-1.1252 2.5-2.5-1.1252-2.5-2.5-2.5zm0 1c0.83435 0 1.5 0.66565 1.5 1.5s-0.66565 1.5-1.5 1.5-1.5-0.66565-1.5-1.5 0.66565-1.5 1.5-1.5z',
    ],
};

export const faFile: CustomIcon = {
    prefix: 'fas',
    iconName: 'file' as IconName,
    icon: [
        14, 17,
        [],
        '',
        'm2.5 0.5c-0.53024 0-1.0391 0.211-1.4141 0.58594-0.37494 0.37494-0.58594 0.88382-0.58594 1.4141v12c0 0.53023 0.21098 1.0391 0.58594 1.4141 0.37495 0.37495 0.88385 0.58594 1.4141 0.58594h9c0.53019 0 1.0391-0.21096 1.4141-0.58594 0.37498-0.37498 0.58594-0.88387 0.58594-1.4141v-8.25a0.5 0.5 0 0 0-0.14648-0.35352l-5.25-5.25a0.5 0.5 0 0 0-0.35352-0.14648h-5.25zm0 1h4.75v4.75a0.50005 0.50005 0 0 0 0.5 0.5h4.75v7.75c0 0.2654-0.10535 0.51941-0.29297 0.70703s-0.44163 0.29297-0.70703 0.29297h-9c-0.26543 0-0.51938-0.10532-0.70703-0.29297-0.18764-0.18764-0.29297-0.44166-0.29297-0.70703v-12c0-0.2654 0.1053-0.51937 0.29297-0.70703s0.44163-0.29297 0.70703-0.29297zm5.75 0.70703 3.543 3.543h-3.543v-3.543z',
    ],
};

export const faGrid: CustomIcon = {
    prefix: 'fas',
    iconName: 'grid' as IconName,
    icon: [
        16, 15,
        [],
        '',
        'm2.2207 0c-0.88662 0-1.6055 0.71886-1.6055 1.6055v3.2109c0 0.88661 0.71886 1.6055 1.6055 1.6055h3.2109c0.88661 0 1.6055-0.71886 1.6055-1.6055v-3.2109c0-0.88661-0.71886-1.6055-1.6055-1.6055h-3.2109zm8.3477 0c-0.88662 0-1.6055 0.71886-1.6055 1.6055v3.2109c0 0.88661 0.71885 1.6055 1.6055 1.6055h3.2109c0.8866 0 1.6055-0.71886 1.6055-1.6055v-3.2109c0-0.88661-0.71887-1.6055-1.6055-1.6055h-3.2109zm-8.3477 8.3477c-0.88662 0-1.6055 0.71886-1.6055 1.6055v3.2109c0 0.8866 0.71886 1.6055 1.6055 1.6055h3.2109c0.88661 0 1.6055-0.71887 1.6055-1.6055v-3.2109c0-0.88661-0.71886-1.6055-1.6055-1.6055h-3.2109zm8.3477 0c-0.88662 0-1.6055 0.71886-1.6055 1.6055v3.2109c0 0.8866 0.71885 1.6055 1.6055 1.6055h3.2109c0.8866 0 1.6055-0.71887 1.6055-1.6055v-3.2109c0-0.88661-0.71887-1.6055-1.6055-1.6055h-3.2109z',
    ],
};

export const faHamburger: CustomIcon = {
    prefix: 'fas',
    iconName: 'hamburger' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm3.5 4c-0.277 0-0.5 0.223-0.5 0.5s0.223 0.5 0.5 0.5h9c0.277 0 0.5-0.223 0.5-0.5s-0.223-0.5-0.5-0.5h-9zm0 3c-0.277 0-0.5 0.223-0.5 0.5s0.223 0.5 0.5 0.5h9c0.277 0 0.5-0.223 0.5-0.5s-0.223-0.5-0.5-0.5h-9zm0 3c-0.277 0-0.5 0.223-0.5 0.5s0.223 0.5 0.5 0.5h9c0.277 0 0.5-0.223 0.5-0.5s-0.223-0.5-0.5-0.5h-9z',
    ],
};

export const faInfo: CustomIcon = {
    prefix: 'fas',
    iconName: 'info' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8 0.83398c-3.9521 0-7.166 3.2139-7.166 7.166 0 3.9522 3.2139 7.166 7.166 7.166 3.9521 0 7.166-3.2139 7.166-7.166 0-3.9521-3.2139-7.166-7.166-7.166zm0 1c3.4116 0 6.166 2.7543 6.166 6.166 0 3.4117-2.7544 6.166-6.166 6.166-3.4117 0-6.166-2.7543-6.166-6.166 0-3.4117 2.7543-6.166 6.166-6.166zm0 3a0.5 0.5 0 0 0-0.5 0.5 0.5 0.5 0 0 0 0.5 0.5h0.0058594a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-0.0058594zm0 2.666a0.5 0.5 0 0 0-0.5 0.5v2.666a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-2.666a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faKey: CustomIcon = {
    prefix: 'fas',
    iconName: 'key' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm13.646 0.98047-1.334 1.332-2.332 2.334-2.4629 2.4629c-0.72878-0.54934-1.586-0.90644-2.5078-0.89844-1.0923 0.0094871-2.1378 0.4483-2.9102 1.2207-0.77241 0.77241-1.2112 1.8178-1.2207 2.9102-0.0094872 1.0922 0.41103 2.1459 1.1699 2.9316a0.50005 0.50005 0 0 0 0.0039063 0.003906c0.38593 0.39111 0.8449 0.70121 1.3516 0.91406 0.50666 0.21286 1.0501 0.32435 1.5996 0.32617 0.5496 0.0019 1.0936-0.10504 1.6016-0.31445 0.50803-0.20942 0.97079-0.5176 1.3594-0.90625 0.3886-0.38854 0.69681-0.85132 0.90625-1.3594 0.20943-0.50805 0.31629-1.052 0.31445-1.6016-0.0018409-0.5495-0.11333-1.093-0.32617-1.5996-0.14606-0.34767-0.39426-0.63298-0.62695-0.92773l2.1016-2.1016 1.6465 1.6465a0.50005 0.50005 0 0 0 0.70703 0l2.332-2.334a0.50005 0.50005 0 0 0 0-0.70703l-1.6465-1.6465 0.98047-0.97852-0.70703-0.70703zm-0.98047 2.3926 1.293 1.293-1.625 1.627-1.293-1.293 1.625-1.627zm-7.6484 3.8379c0.82985-0.00721 1.6293 0.31254 2.2266 0.88867h0.0019532c0.29546 0.29254 0.53035 0.64008 0.69141 1.0234 0.16176 0.38505 0.24665 0.79918 0.24805 1.2168 0.0014 0.41767-0.08105 0.83065-0.24023 1.2168-0.15918 0.38614-0.3922 0.73794-0.6875 1.0332-0.29529 0.29534-0.64707 0.52832-1.0332 0.6875-0.38615 0.15918-0.79924 0.2417-1.2168 0.24023-0.41766-0.0014-0.8318-0.0863-1.2168-0.24805-0.38333-0.16104-0.73088-0.39594-1.0234-0.69141v-0.001953c-0.5761-0.59711-0.89588-1.3949-0.88867-2.2246 0.0072131-0.83052 0.34048-1.6256 0.92773-2.2129 0.58725-0.58725 1.3805-0.92052 2.2109-0.92773z',
    ],
};

export const faListActive: CustomIcon = {
    prefix: 'fas',
    iconName: 'list-active' as IconName,
    icon: [
        17, 14,
        [],
        '',
        'm1.6992 0.44336a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0781h0.0078124a1.0781 1.0781 0 0 0 1.0781-1.0781 1.0781 1.0781 0 0 0-1.0781-1.0781h-0.0078124zm3.8945 0a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0781h10.121a1.0781 1.0781 0 0 0 1.0781-1.0781 1.0781 1.0781 0 0 0-1.0781-1.0781h-10.121zm-3.8945 5.6074a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0762h0.0078124a1.0781 1.0781 0 0 0 1.0781-1.0762 1.0781 1.0781 0 0 0-1.0781-1.0781h-0.0078124zm3.8945 0a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0762h10.121a1.0781 1.0781 0 0 0 1.0781-1.0762 1.0781 1.0781 0 0 0-1.0781-1.0781h-10.121zm-3.8945 5.6055a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0781h0.0078124a1.0781 1.0781 0 0 0 1.0781-1.0781 1.0781 1.0781 0 0 0-1.0781-1.0781h-0.0078124zm3.8945 0a1.0781 1.0781 0 0 0-1.0781 1.0781 1.0781 1.0781 0 0 0 1.0781 1.0781h10.121a1.0781 1.0781 0 0 0 1.0781-1.0781 1.0781 1.0781 0 0 0-1.0781-1.0781h-10.121z',
    ],
};

export const faNotification: CustomIcon = {
    prefix: 'fas',
    iconName: 'notification' as IconName,
    icon: [
        17, 19,
        [],
        '',
        'm8.6504 0.5c-1.4853 0-2.9087 0.5904-3.959 1.6406-1.0502 1.0502-1.6406 2.4757-1.6406 3.9609 0 2.9041-0.61993 4.7257-1.2148 5.8164-0.59491 1.0907-1.1133 1.418-1.1133 1.418a0.50005 0.50005 0 0 0 0.27734 0.91602h15.303a0.50005 0.50005 0 0 0 0.27734-0.91602s-0.51838-0.32729-1.1133-1.418c-0.5949-1.0907-1.2148-2.9123-1.2148-5.8164 0-1.4853-0.59041-2.9107-1.6406-3.9609-1.0502-1.0502-2.4757-1.6406-3.9609-1.6406zm0 1c1.2204 0 2.3909 0.48469 3.2539 1.3477 0.86298 0.86299 1.3477 2.0335 1.3477 3.2539 0 3.047 0.65564 5.0496 1.3359 6.2969 0.32813 0.60159 0.42321 0.56624 0.69336 0.85352h-13.26c0.27015-0.28728 0.36522-0.25192 0.69336-0.85352 0.68031-1.2472 1.3359-3.2499 1.3359-6.2969 0-1.2204 0.48469-2.3909 1.3477-3.2539 0.86298-0.86297 2.0315-1.3477 3.252-1.3477zm-1.4707 15.152a0.5 0.5 0 0 0-0.25 0.068359 0.5 0.5 0 0 0-0.18164 0.68359c0.1934 0.33346 0.47059 0.61039 0.80469 0.80273 0.33397 0.1924 0.71411 0.29297 1.0996 0.29297 0.38549 0 0.76368-0.10057 1.0977-0.29297 0.3341-0.19234 0.61131-0.46926 0.80469-0.80273a0.5 0.5 0 0 0-0.18164-0.68359 0.5 0.5 0 0 0-0.37891-0.050781 0.5 0.5 0 0 0-0.30469 0.23242c-0.1055 0.18193-0.25732 0.33264-0.43945 0.4375-0.18226 0.105-0.38741 0.16016-0.59766 0.16016-0.21026 0-0.41734-0.055155-0.59961-0.16016-0.18214-0.10486-0.33393-0.25556-0.43945-0.4375a0.5 0.5 0 0 0-0.43359-0.25z',
    ],
};

export const faPencilLarge: CustomIcon = {
    prefix: 'fas',
    iconName: 'pencil-large' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm12.666 0.94727c-0.31327 0-0.62268 0.06177-0.91211 0.18164-0.28932 0.11988-0.55189 0.29603-0.77344 0.51758l-9 9a0.50005 0.50005 0 0 0-0.12891 0.22266l-1 3.666a0.50005 0.50005 0 0 0 0.61328 0.61328l3.666-1a0.50005 0.50005 0 0 0 0.22266-0.12891l9-9c0.22152-0.22152 0.39773-0.48392 0.51758-0.77344 0.11998-0.28955 0.18164-0.59893 0.18164-0.91211 0-0.31318-0.06165-0.62451-0.18164-0.91406-0.11984-0.28953-0.29605-0.55192-0.51758-0.77344-0.22155-0.22155-0.48399-0.3977-0.77344-0.51758-0.28938-0.11985-0.60072-0.18164-0.91406-0.18164zm0 1c0.18206 0 0.36303 0.0358 0.53125 0.10547 0.16815 0.069642 0.32057 0.17213 0.44922 0.30078 0.12867 0.12866 0.23123 0.28119 0.30078 0.44922 0.06961 0.16799 0.10547 0.34919 0.10547 0.53125s-0.03585 0.36129-0.10547 0.5293c-0.069555 0.16803-0.1721 0.32054-0.30078 0.44922l-8.9062 8.9062-2.6934 0.73438 0.73438-2.6934 8.9062-8.9063c0.12865-0.12865 0.28114-0.23114 0.44922-0.30078 0.16817-0.069649 0.34737-0.10547 0.5293-0.10547z',
    ],
};

export const faPencil: CustomIcon = {
    prefix: 'fas',
    iconName: 'pencil' as IconName,
    icon: [
        6, 6,
        [],
        '',
        'm4.75 0.042969c-0.15851 0-0.31644 0.031137-0.46289 0.091797-0.14645 0.060662-0.27854 0.14963-0.39062 0.26172l-3.375 3.375a0.50005 0.50005 0 0 0-0.12891 0.22266l-0.375 1.375a0.50005 0.50005 0 0 0 0.61328 0.61328l1.375-0.375a0.50005 0.50005 0 0 0 0.22266-0.12891l3.375-3.375c0.11205-0.11207 0.20105-0.24414 0.26172-0.39062 0.060673-0.14646 0.091797-0.30438 0.091797-0.46289 0-0.15851-0.031127-0.31448-0.091797-0.46094-0.060665-0.14648-0.14964-0.2805-0.26172-0.39258-0.11208-0.11209-0.24417-0.20106-0.39062-0.26172-0.14645-0.06066-0.30438-0.091797-0.46289-0.091797zm0 1c0.027206 0 0.054945 0.0052099 0.080078 0.015625 0.025127 0.010408 0.04717 0.025685 0.066406 0.044922 0.019243 0.019243 0.034527 0.041308 0.044922 0.066406 0.01041 0.025129 0.015625 0.052872 0.015625 0.080078 0 0.027189-0.0052199 0.054958-0.015625 0.080078-0.010394 0.025098-0.025652 0.047134-0.044922 0.066406l-3.2812 3.2812-0.40234 0.10938 0.10938-0.40234 3.2812-3.2812c0.019236-0.019237 0.041279-0.034514 0.066406-0.044922 0.025133-0.01041 0.052872-0.015625 0.080078-0.015625z',
    ],
};

export const faPlaySolid: CustomIcon = {
    prefix: 'fas',
    iconName: 'play-solid' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm5.5 3.6699v8.6602l7.5-4.3301-7.5-4.3301z',
    ],
};

export const faPlay: CustomIcon = {
    prefix: 'fas',
    iconName: 'play' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm5.5 2.6719c-0.17282 0-0.34565 0.043692-0.5 0.13281-0.30869 0.17823-0.5 0.50877-0.5 0.86523v8.6602c0 0.35646 0.19135 0.68706 0.5 0.86523 0.30874 0.17842 0.69126 0.17842 1 0l7.5-4.3301c0.30873-0.17823 0.5-0.50877 0.5-0.86523s-0.19127-0.687-0.5-0.86523l-7.5-4.3301c-0.15435-0.089121-0.32718-0.13281-0.5-0.13281zm0 0.99805 7.5 4.3301-7.5 4.3301v-8.6602z',
    ],
};

export const faPlusLarge: CustomIcon = {
    prefix: 'fas',
    iconName: 'plus-large' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8 0.5a0.5 0.5 0 0 0-0.5 0.5v6.5h-6.5a0.5 0.5 0 0 0-0.5 0.5 0.5 0.5 0 0 0 0.5 0.5h6.5v6.5a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-6.5h6.5a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-6.5v-6.5a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faPlus: CustomIcon = {
    prefix: 'fas',
    iconName: 'plus' as IconName,
    icon: [
        8, 8,
        [],
        '',
        'm4 0.5a0.5 0.5 0 0 0-0.5 0.5v2.5h-2.5a0.5 0.5 0 0 0-0.5 0.5 0.5 0.5 0 0 0 0.5 0.5h2.5v2.5a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-2.5h2.5a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-2.5v-2.5a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faRefresh: CustomIcon = {
    prefix: 'fas',
    iconName: 'refresh' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.3555 2.502c-0.41866 0.01252-0.83653 0.077977-1.2422 0.19531-0.80991 0.23427-1.548 0.66987-2.1445 1.2656-0.00104 0.00104-0.0028563 9.13e-4 -0.0039062 0.0019532l-1.4648 1.3789v-1.8457a0.5 0.5 0 0 0-0.5-0.5 0.5 0.5 0 0 0-0.5 0.5v3a0.5 0.5 0 0 0 0.13477 0.32617 0.5 0.5 0 0 0 0 0.017578 0.5 0.5 0 0 0 0.0097656 0.0039062 0.5 0.5 0 0 0 0.14062 0.064453 0.5 0.5 0 0 0 0.20703 0.083984 0.5 0.5 0 0 0 0.0078125 0.0039063h3a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-1.7363l1.3984-1.3145a0.50005 0.50005 0 0 0 0.0097656-0.011719c0.47768-0.47792 1.0696-0.82591 1.7188-1.0137 0.6491-0.18775 1.3351-0.20967 1.9941-0.060547 0.65903 0.14912 1.2704 0.46325 1.7754 0.91211 0.50508 0.44889 0.88796 1.0193 1.1133 1.6562a0.5 0.5 0 0 0 0.63672 0.30469 0.5 0.5 0 0 0 0.30469-0.63867c-0.28168-0.79625-0.75931-1.5073-1.3906-2.0684-0.63124-0.56112-1.395-0.95424-2.2188-1.1406-0.41189-0.093198-0.83134-0.13361-1.25-0.12109zm1.3906 6.498a0.5 0.5 0 0 0-0.5 0.5 0.5 0.5 0 0 0 0.5 0.5h1.7363l-1.4004 1.3164a0.50005 0.50005 0 0 0-0.009765 0.011719c-0.47767 0.47796-1.0696 0.82593-1.7188 1.0137-0.64907 0.18782-1.3351 0.20775-1.9941 0.058594-0.659-0.14906-1.2703-0.46124-1.7754-0.91016-0.50508-0.44892-0.88787-1.0192-1.1133-1.6562a0.5 0.5 0 0 0-0.63672-0.30469 0.5 0.5 0 0 0-0.30469 0.63867c0.28175 0.79632 0.75937 1.5073 1.3906 2.0684 0.63126 0.56108 1.3949 0.95428 2.2188 1.1406 0.82378 0.18644 1.6808 0.16056 2.4922-0.074219 0.81132-0.23466 1.5513-0.6721 2.1484-1.2695l1.4668-1.377v1.8438a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-3a0.50005 0.50005 0 0 0-0.00586-0.0058594 0.5 0.5 0 0 0-0.13086-0.33594 0.5 0.5 0 0 0-0.35742-0.15234 0.50005 0.50005 0 0 0-0.005859-0.0058594h-3z',
    ],
};

export const faReloadLarge: CustomIcon = {
    prefix: 'fas',
    iconName: 'reload-large' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm8.5488 1.5c-0.26198 0.0048049-0.52547 0.025688-0.78711 0.0625-1.3954 0.19632-2.6902 0.84231-3.6875 1.8379l0.0097656-0.011719-2.25 2.1172v-2.8457a0.5 0.5 0 0 0-0.5-0.5 0.5 0.5 0 0 0-0.5 0.5v3.9844 0.015625a0.50005 0.50005 0 0 0 0.0097656 0.0097657 0.5 0.5 0 0 0 0.125 0.33203 0.5 0.5 0 0 0 0.36133 0.1543 0.50005 0.50005 0 0 0 0.0039063 0.0039062h4a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-2.7383l2.1738-2.043a0.50005 0.50005 0 0 0 0.011719-0.0097656c0.84394-0.84251 1.9383-1.3886 3.1191-1.5547 1.1809-0.16615 2.384 0.057811 3.4277 0.63477 1.0437 0.57694 1.8701 1.4761 2.3574 2.5645 0.48733 1.0884 0.60832 2.306 0.34375 3.4688-0.26456 1.1628-0.90022 2.2082-1.8105 2.9785-0.9104 0.77032-2.0458 1.2224-3.2363 1.291-1.1906 0.06848-2.3706-0.25134-3.3633-0.91211-0.99271-0.66077-1.7444-1.6253-2.1406-2.75a0.5 0.5 0 0 0-0.63672-0.30664 0.5 0.5 0 0 0-0.30664 0.63867c0.46826 1.3291 1.3562 2.4692 2.5293 3.25 1.1731 0.78083 2.5678 1.161 3.9746 1.0801 1.4068-0.08103 2.7504-0.61707 3.8262-1.5273 1.0757-0.91026 1.826-2.1474 2.1387-3.5215 0.31263-1.374 0.17157-2.8116-0.4043-4.0977-0.57588-1.2861-1.5558-2.3514-2.7891-3.0332-1.0021-0.55393-2.1265-0.83137-3.2617-0.81055z',
    ],
};

export const faReload: CustomIcon = {
    prefix: 'fas',
    iconName: 'reload' as IconName,
    icon: [
        6, 6,
        [],
        '',
        'm3.1992 0.25195c-0.11083 0.0020357-0.22134 0.0098177-0.33203 0.025391-0.59032 0.083056-1.1386 0.35615-1.5605 0.77734l-0.30664 0.28711v-0.34375a0.5 0.5 0 0 0-0.5-0.5 0.5 0.5 0 0 0-0.5 0.5v1.5a0.50005 0.50005 0 0 0 0.14258 0.14258 0.5 0.5 0 0 0-0.0058594 0.19922 0.5 0.5 0 0 0 0.35938 0.1543 0.50005 0.50005 0 0 0 0.0039062 0.0039063h1.5a0.5 0.5 0 0 0 0.5-0.5 0.5 0.5 0 0 0-0.5-0.5h-0.23828l0.24023-0.22656a0.50005 0.50005 0 0 0 0.011719-0.0097656c0.26856-0.26811 0.61641-0.44127 0.99219-0.49414 0.37579-0.05287 0.75773 0.017576 1.0898 0.20117 0.33212 0.1836 0.59686 0.47006 0.75195 0.81641 0.1551 0.34635 0.19358 0.73348 0.10938 1.1035-0.084201 0.37001-0.28648 0.70213-0.57617 0.94727-0.28969 0.24513-0.6524 0.3903-1.0312 0.41211-0.37885 0.021805-0.75441-0.08074-1.0703-0.29102-0.3159-0.21027-0.55359-0.51709-0.67969-0.875a0.5 0.5 0 0 0-0.63867-0.30664 0.5 0.5 0 0 0-0.30469 0.63867c0.1981 0.56227 0.57406 1.0447 1.0703 1.375 0.49626 0.33032 1.0865 0.49129 1.6816 0.45703 0.59516-0.034255 1.1621-0.26141 1.6172-0.64648 0.45507-0.38508 0.77397-0.90895 0.90625-1.4902 0.13228-0.58128 0.071769-1.1883-0.17188-1.7324-0.24364-0.54407-0.65795-0.99478-1.1797-1.2832-0.42391-0.23434-0.90058-0.35062-1.3809-0.3418z',
    ],
};

export const faSave: CustomIcon = {
    prefix: 'fas',
    iconName: 'save' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'm4.1113 2.5c-0.42729 0-0.83652 0.17052-1.1387 0.47266-0.30214 0.30215-0.47266 0.71138-0.47266 1.1387v7.7773c0 0.4273 0.17052 0.83657 0.47266 1.1387 0.30215 0.3022 0.71138 0.47266 1.1387 0.47266h1.0781c0.01097 7e-4 0.022043 0.001953 0.033203 0.001953 0.01115 0 0.022233-0.001253 0.033203-0.001953h5.4883c0.011 7e-4 0.022103 0.001953 0.033203 0.001953 0.0112 0 0.022203-0.001253 0.033203-0.001953h1.0781c0.4273 0 0.83657-0.17046 1.1387-0.47266 0.3022-0.3021 0.47266-0.71137 0.47266-1.1387v-6.1113c0-0.13261-0.052784-0.25975-0.14648-0.35352l-2.7773-2.7773c-0.0938-0.09377-0.22092-0.14648-0.35352-0.14648h-6.1113zm0 1h0.61133v2.2773c0 0.27614 0.22385 0.5 0.5 0.5h4.4453c0.27614 0 0.5-0.22386 0.5-0.5 0-0.27614-0.22386-0.5-0.5-0.5h-3.9453v-1.7773h4.293l2.4844 2.4844v5.9043c0 0.1621-0.065088 0.31704-0.17969 0.43164-0.1146 0.1146-0.26954 0.17969-0.43164 0.17969h-0.61133v-3.9434c0-0.27614-0.2238-0.5-0.5-0.5h-5.5547c-0.27615 0-0.5 0.22386-0.5 0.5v3.9434h-0.61133c-0.16208 0-0.31703-0.065087-0.43164-0.17969-0.11461-0.1146-0.17969-0.26954-0.17969-0.43164v-7.7773c0-0.16208 0.065078-0.31703 0.17969-0.43164s0.26956-0.17969 0.43164-0.17969zm1.6113 5.5566h4.5547v3.4434h-4.5547v-3.4434z',
    ],
};

export const faSearch: CustomIcon = {
    prefix: 'fas',
    iconName: 'search' as IconName,
    icon: [
        12, 13,
        [],
        '',
        'm5.3887 0.5c-2.9703 0-5.3887 2.4184-5.3887 5.3887 0 2.9703 2.4184 5.3887 5.3887 5.3887 1.3022 0 2.4539-0.52253 3.3867-1.2949l2.3711 2.3711a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-2.3711-2.3711c0.77239-0.93281 1.2949-2.0845 1.2949-3.3867 0-2.9703-2.4184-5.3887-5.3887-5.3887zm0 1c2.4298 0 4.3887 1.9588 4.3887 4.3887 0 1.2124-0.48873 2.3065-1.2793 3.0996a0.5 0.5 0 0 0-0.0097657 0 0.5 0.5 0 0 0 0 0.0097657c-0.7931 0.79056-1.8872 1.2793-3.0996 1.2793-2.4298 0-4.3887-1.9588-4.3887-4.3887 0-2.4298 1.9588-4.3887 4.3887-4.3887z',
    ],
};

export const faShare: CustomIcon = {
    prefix: 'fas',
    iconName: 'share' as IconName,
    icon: [
        16, 16,
        [],
        '',
        'M 12.5 0 C 10.987172 0 9.75 1.2371304 9.75 2.75 C 9.75 3.0712065 9.9914139 3.2870734 10.09375 3.5742188 L 5.5175781 6.2441406 C 5.0123622 5.6599032 4.3285707 5.25 3.5 5.25 C 1.9871411 5.25 0.75 6.4871411 0.75 8 C 0.75 9.5128589 1.9871411 10.75 3.5 10.75 C 4.3288408 10.75 5.0143077 10.340426 5.5195312 9.7558594 L 10.095703 12.421875 C 9.9924122 12.710207 9.75 12.927233 9.75 13.25 C 9.75 14.762838 10.987162 16 12.5 16 C 14.012838 16 15.25 14.762838 15.25 13.25 C 15.25 11.737162 14.012838 10.5 12.5 10.5 C 11.672737 10.5 10.987602 10.907576 10.482422 11.490234 L 5.90625 8.8261719 C 6.0089791 8.538535 6.25 8.3218527 6.25 8 C 6.25 7.6787958 6.0085841 7.462926 5.90625 7.1757812 L 10.480469 4.5058594 C 10.985697 5.0904251 11.671176 5.5 12.5 5.5 C 14.012828 5.5 15.25 4.2628696 15.25 2.75 C 15.25 1.2371304 14.012828 0 12.5 0 z M 12.5 1 C 13.47237 1 14.25 1.7775921 14.25 2.75 C 14.25 3.7224079 13.47237 4.5 12.5 4.5 C 11.52763 4.5 10.75 3.7224079 10.75 2.75 C 10.75 1.7775921 11.52763 1 12.5 1 z M 3.5 6.25 C 4.4724186 6.25 5.25 7.0275814 5.25 8 C 5.25 8.9724186 4.4724186 9.75 3.5 9.75 C 2.5275814 9.75 1.75 8.9724186 1.75 8 C 1.75 7.0275814 2.5275814 6.25 3.5 6.25 z M 12.5 11.5 C 13.472359 11.5 14.25 12.277641 14.25 13.25 C 14.25 14.222359 13.472359 15 12.5 15 C 11.527641 15 10.75 14.222359 10.75 13.25 C 10.75 12.277641 11.527641 11.5 12.5 11.5 z ',
    ],
};

export const faSpeakerMuted: CustomIcon = {
    prefix: 'fas',
    iconName: 'speaker-muted' as IconName,
    icon: [
        17, 16,
        [],
        '',
        'm8.8449 15.474c-0.07314-3e-4 -0.14549-0.0152-0.21287-0.0436-0.06737-0.0285-0.12842-0.07-0.17962-0.1223l-4.2124-4.2566h-3.6872c-0.14661 0-0.28722-0.0583-0.3909-0.1619-0.10367-0.1037-0.16191-0.2443-0.16191-0.3909v-5.5281c0-0.14661 0.058242-0.28722 0.16191-0.39089 0.10367-0.10368 0.24428-0.16192 0.3909-0.16192h3.6872l4.2124-4.2566c0.10357-0.10296 0.24368-0.16075 0.38973-0.16075 0.14604 0 0.28615 0.057792 0.38973 0.16075 0.10416 0.10206 0.16376 0.24115 0.16584 0.38697v14.373c0 0.1466-0.05824 0.2872-0.16191 0.3908-0.10367 0.1037-0.24428 0.162-0.3909 0.162zm7.1866-9.724-0.7795-0.77946-1.9846 1.9846-1.9846-1.9846-0.7794 0.77946 1.9846 1.9846-1.9846 1.9846 0.7794 0.77948 1.9846-1.9846 1.9846 1.9846 0.7795-0.77948-1.9846-1.9846z',
    ],
};

export const faUpload: CustomIcon = {
    prefix: 'fas',
    iconName: 'upload' as IconName,
    icon: [
        14, 16,
        [],
        '',
        'm7 0.5a0.5 0.5 0 0 0-0.35352 0.14648l-2.8008 2.8008a0.5 0.5 0 0 0 0 0.70703 0.5 0.5 0 0 0 0.70703 0l1.9473-1.9473v7.8926a0.5 0.5 0 0 0 0.5 0.5 0.5 0.5 0 0 0 0.5-0.5v-7.8926l1.9473 1.9473a0.5 0.5 0 0 0 0.70703 0 0.5 0.5 0 0 0 0-0.70703l-2.8008-2.8008a0.5 0.5 0 0 0-0.35352-0.14648zm-5.5996 7a0.5 0.5 0 0 0-0.5 0.5v5.5996c0 0.50372 0.20042 0.9876 0.55664 1.3438 0.35619 0.35626 0.84003 0.55664 1.3438 0.55664h8.3984c0.50372 0 0.98753-0.20042 1.3438-0.55664 0.35615-0.35615 0.55664-0.84003 0.55664-1.3438v-5.5996a0.5 0.5 0 0 0-0.5-0.5 0.5 0.5 0 0 0-0.5 0.5v5.5996c0 0.23888-0.094821 0.46787-0.26367 0.63672-0.16898 0.16898-0.39784 0.26367-0.63672 0.26367h-8.3984c-0.23888 0-0.46781-0.094728-0.63672-0.26367-0.16888-0.16884-0.26367-0.39784-0.26367-0.63672v-5.5996a0.5 0.5 0 0 0-0.5-0.5z',
    ],
};

export const faVideo: CustomIcon = {
    prefix: 'fas',
    iconName: 'video' as IconName,
    icon: [
        19, 13,
        [],
        '',
        'm2.5449 0.5c-1.1237 0-2.0449 0.92117-2.0449 2.0449v7.7285c0 1.1238 0.92118 2.0449 2.0449 2.0449h8.5c1.1237 0 2.0469-0.92114 2.0469-2.0449v-2.8926l4.6172 3.2988a0.50005 0.50005 0 0 0 0.79102-0.40625v-7.7285a0.50005 0.50005 0 0 0-0.79102-0.40625l-4.6172 3.2988v-2.8926c0-1.1237-0.92315-2.0449-2.0469-2.0449h-8.5zm0 1h8.5c0.58327 0 1.0469 0.4616 1.0469 1.0449v3.8633 3.8652c0 0.58341-0.4636 1.0449-1.0469 1.0449h-8.5c-0.58332 0-1.0449-0.46152-1.0449-1.0449v-7.7285c0-0.58331 0.46161-1.0449 1.0449-1.0449zm14.955 2.0176v5.7832l-4.0488-2.8906 4.0488-2.8926z',
    ],
};
