import {faAddPeople,
    faBolt,
    faBoltSolid,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faComment,
    faCommentFilled,
    faCopy,
    faCrossLarge,
    faCrossSmall,
    faEnter,
    faEnterLarge,
    faEye,
    faEyeClosed,
    faFile,
    faGrid,
    faHamburger,
    faInfo,
    faKey,
    faListActive,
    faNotification,
    faPencil,
    faPencilLarge,
    faPlay,
    faPlaySolid,
    faPlus,
    faPlusLarge,
    faRefresh,
    faReload,
    faReloadLarge,
    faSave,
    faSearch,
    faShare,
    faSpeakerMuted,
    faUpload,
    faVideo} from '@/library/customIcons';
import {library} from '@fortawesome/fontawesome-svg-core';

// Import brand icons
// eslint-disable-next-line
import {faGithub} from '@fortawesome/free-brands-svg-icons';
// eslint-disable-next-line
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

export default {
    init(): void {
        library.add(
            faAddPeople,
            faBoltSolid,
            faBolt,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronUp,
            faCommentFilled,
            faComment,
            faCopy,
            faCrossLarge,
            faCrossSmall,
            faEnterLarge,
            faEnter,
            faEnvelope,
            faEyeClosed,
            faEye,
            faFile,
            faGithub,
            faGrid,
            faHamburger,
            faInfo,
            faKey,
            faListActive,
            faNotification,
            faPencilLarge,
            faPencil,
            faPlaySolid,
            faPlay,
            faPlusLarge,
            faPlus,
            faRefresh,
            faReloadLarge,
            faReload,
            faSave,
            faSearch,
            faShare,
            faSpeakerMuted,
            faUpload,
            faVideo,
        );
    },
};
